<template>
  <div class="flex bg-layouts">
    <Sidebar> </Sidebar>
    <div class="container mx-12 max-w-none blue-express-calc">
      <div class="bg-adminbar">
        <div class="flex justify-between items-center mx-8">
          <img
            class="logo cursor-pointer"
            src="../../assets/images/Logotipo_Bluexpress.svg"
            alt=""
            @click="navHome"
          />
          <div class="flex justify-center items-center">
            <div @click="logOut">
              <img
                src="../../assets/images/logout.svg"
                class="cursor-pointer w-6 icon"
                alt=""
              />
            </div>
            <div class="mx-8">
              <img src="../../assets/images/bell.svg" class="icon" alt="" />
            </div>
            <div class="mx-2 flex flex-col user-text">
              <span class="user-text">Yaira</span>
              <span class="job-text">Mercadeo</span>
            </div>
            <div>
              <img class="avatar" src="../../assets/images/oval.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="my-8 admin-module">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/SideBar/Index";
export default {
  components: {
    Sidebar,
  },
  methods: {
    logOut() {
      this.$store.dispatch("UsersModule/signOut").then(() => {
        this.$router.push("/");
      });
    },
    navHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style>
.bg-adminbar {
  background-color: #0276ff;
  width: calc(100% - 280px);
  margin-left: 280px;
  height: 6vh;
  max-height: 52px;
  margin-top: 15px;
  border: 2px solid transparent;
  border-radius: 10px;
}

.admin-module {
  width: calc(100% - 280px);
  margin-left: 280px;
}

.user-text {
  font-family: Poppins;
  font-weight: 300;
  font-size: 11px;
  text-align: left;
  color: #fff;
}

.blue-express-calc {
  height: auto !important;
}

.bg-layouts {
  background-color: #f9f9f9;
  height: auto;
}

@media (max-height: 899px) {
  .logo {
    height: 30px;
    width: auto;
  }
  .avatar {
    height: 40px;
  }
}
@media (max-width: 1439px) {
  .bg-adminbar {
    width: calc(100% - 200px);
    margin-left: 200px;
  }
  .admin-module {
  width: calc(100% - 200px);
  margin-left: 200px;
}
}
</style>
