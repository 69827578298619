<template>
  <div class="bg-admin flex flex-col">
    <div class="flex justify-center my-8 mx-8">
      <img
        src="../../assets/images/Logotipo_Bluexpress.svg"
        alt=""
        class="cursor-pointer"
        @click="navHome"
      />
    </div>
    <vs-sidebar background="primary" textWhite v-model="active" open>
      <vs-sidebar-item id="home">
        <template #icon>
          <img src="../../assets/images/home.svg" alt="" />
        </template>
        Dashboard
      </vs-sidebar-item>
      <vs-sidebar-item id="uploader">
        <template #icon>
          <img src="../../assets/images/upload-iconW.svg" alt="" />
        </template>
        <router-link to="/admin">Uploader</router-link>
      </vs-sidebar-item>
      <vs-sidebar-group>
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <img src="../../assets/images/pages.svg" alt="" />
            </template>
            Pages
          </vs-sidebar-item>
        </template>

        <vs-sidebar-item id="about">
          <template> </template>
          <router-link to="/admin/about-us"> Nosotros </router-link>
        </vs-sidebar-item>
        <vs-sidebar-item id="Distribuitor">
          <template> </template>
          <router-link to="/admin/pages">
            Quieres ser distribuidor?
          </router-link>
        </vs-sidebar-item>
        <!-- <vs-sidebar-item id="NewPage">
          <template> </template>
          + Añadir pagina nueva
        </vs-sidebar-item> -->
      </vs-sidebar-group>
      <vs-sidebar-item id="Music">
        <template #icon>
          <img src="../../assets/images/icon-admin.svg" alt="" />
        </template>
        Admin Users
      </vs-sidebar-item>
      <vs-sidebar-item id="EdataBase">
        <template #icon>
          <img src="../../assets/images/mail-icon.svg" alt="" />
        </template>
        Email Database
      </vs-sidebar-item>
    </vs-sidebar>
    <!-- <button
      class="text-left ml-12 text-sm text-gray-300 cursor-pointer hover:text-white"
      @click="logOut"
    >
      Log Out
    </button> -->
  </div>
</template>

<script>
export default {
  data: () => ({
    active: "home",
  }),
  methods: {
    logOut() {
      this.$store.dispatch("UsersModule/signOut").then(() => {
        this.$router.push("/");
      });
    },
    navHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style>
.bg-admin {
  background-color: #0276ff;
  width: 280px;
  position: fixed;
  height: 100vh;
}

.vs-sidebar-content {
  position: static !important;
  width: 100%;
  max-width: 100%;
  height: auto;
  background: transparent !important;
}

.vs-sidebar__item {
  background: transparent !important;
}

.vs-sidebar__item__icon {
  background: transparent !important;
}

.vs-sidebar__group {
  width: auto !important;
  min-width: 180px !important;
}

.vs-sidebar__group__content .vs-sidebar__item {
  background: transparent !important;
}

@media (max-width: 1439px) {
  .bg-admin{
    width: 200px;
  }
.vs-sidebar__item__text {
  font-size: 12px;
}
}
</style>
